.hidden-link {
    opacity: 0;
    transition: opacity 1s ease;
  }

.svg-icon {
    height:2em;
    width:2em;
}

.navbar-custom{
  background-color: #43A2DB; 
}

.Site {
  display: flex; 
  flex-direction: column;
}

.Site-content {
  flex: 1;
  min-height: 100vh;
}

.Site-footer {
	padding-top : 2em;
}